import { useLocation, useNavigate } from "react-router-dom";
import { Input, InputPicker, TextArea } from "../../components";
import { Button } from "../../components/elements/Button";
import { Loader } from "../../components/elements/Loader";
import { useEffect, useMemo, useState } from "react";
import TechTopicService from "../../network/service/TechTopics";
import { TechTopic, TechTopicType } from "../../model/TechTopic";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import UserService from "../../network/service/User";
import User from "../../model/User";
import { FileInput } from "../../components/elements/FileInput";

type Option = {
  label: string;
  value: string;
  key: string;
};

export const AddTechTopicPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const editTechTopic: TechTopic | undefined = state;
  const presentors = editTechTopic?.presentors ?? [];
  const [loading, setLoading] = useState(false);
  const eventDate = new Date(editTechTopic?.date ?? "");
  const [users, setUsers] = useState<User[]>([]);
  const [file, setFile] = useState<File>();
  const [formData, setFormData] = useState({
    title: editTechTopic?.title ?? "",
    description: editTechTopic?.description ?? "",
    date: editTechTopic ? moment(eventDate).format("yyyy-MM") : "",
    file: editTechTopic?.file ?? "",
    type: editTechTopic?.type ?? "tech",
  });
  const [selected, setSelected] = useState<Option[]>(
    presentors.map((e) => ({
      label: e.name,
      value: e.name,
      key: e.id,
    }))
  );

  const options = useMemo(() => {
    return users.map((e) => ({ label: e.name, value: e.name, key: e.id }));
  }, [users]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const result = await UserService.getUsers();
      setUsers(result);
    } catch (error) {
      navigate("../");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const presentors = selected.map((e) => e.key);
    const fd = new FormData();
    if (editTechTopic?.id != null) {
      fd.append("id", editTechTopic.id);
    }
    fd.append("title", formData.title);
    fd.append("type", formData.type);
    fd.append("description", formData.description);
    fd.append("date", new Date(formData.date).toISOString());
    if (presentors.length > 0) {
      presentors.forEach((p, index) => {
        fd.append(`presentors[]`, p);
      });
    }
    if (file != null) {
      fd.append("file", file);
    } else {
      fd.append("file", formData.file);
    }
    try {
      setLoading(true);
      await TechTopicService.addTechTopic(fd);
      navigate("/");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <h2 className="text-center text-2xl font-medium tracking-tight text-gray-900 dark:text-white line-clamp-2 my-10">
        {editTechTopic
          ? "Update Tech/Cultural Event"
          : "Add Tech/Cultural Event"}
      </h2>
      <form className="w-full max-w-sm mx-auto mb-20" onSubmit={onSubmit}>
        <div className="mb-6">
          <Input
            label="Name"
            id="title"
            name="title"
            required
            placeholder="Machine Learning"
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />
        </div>
        <div className="mb-6">
          <TextArea
            rows={5}
            label="Description"
            id="description"
            name="description"
            required
            placeholder="Please enter description"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor={"presentors"}
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {"Presentors"}
            <label className="ml-1 font-medium text-red-600">{"*"}</label>
          </label>
          <MultiSelect
            hasSelectAll={false}
            overrideStrings={{
              allItemsAreSelected: "All employee selected",
              selectSomeItems: "Select Employee",
            }}
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          />
        </div>
        <div className="mb-6">
          <Input
            label="Date"
            id="date"
            name="date"
            type="month"
            required
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          />
        </div>
        <div className="mb-6">
          <InputPicker
            label="Type"
            id="type"
            name="type"
            required
            items={[
              { title: "Tech Event", value: "tech" as TechTopicType },
              { title: "Cultural Event", value: "cultural" as TechTopicType },
            ]}
            value={formData.type}
            onChange={(e) =>
              setFormData({
                ...formData,
                type: e.target.value as TechTopicType,
              })
            }
          />
        </div>
        <div className="mb-16">
          <FileInput
            label="File"
            id="file"
            name="file"
            type="file"
            value={file ? file.name : editTechTopic?.file ?? ""}
            onSelectFile={(e) => setFile(e ? e[0] : undefined)}
          />
        </div>
        <Button type="submit" className="flex mx-auto">
          Submit
        </Button>
      </form>
      <Loader loading={loading} />
    </div>
  );
};
