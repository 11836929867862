export const EndPoints = {
  addUser: "users/add-user",
  getUsers: "users/get-users",
  addTeam: "teams/add-team",
  getTeams: "teams/get-teams",
  addEvent: "events/add-event",
  joinEvent: "events/join-event",
  getEvents: "events/get-events",
  login: "users/login",
  updateTeam: "teams/update-team",
  addPoints: "points/add-points",
  getPoints: "points/get-points/",
  addTechTopic: "tech-topics/add-tech-topic",
  getTechTopics: "tech-topics/get-tech-topic/",
  deleteEvent: "events",
  deleteTechTopic: "tech-topics",
  deleteUser: "users",
  deleteTeams: "teams",
};
