import React from "react";

type Props = {
  title: string;
  children?: any[];
} & React.HTMLAttributes<HTMLElement>;

const styleClasses =
  "text-center uppercase text-3xl font-medium tracking-tight text-gray-900 dark:text-white line-clamp-2 mb-8 ";

export const SectionTitle = (props: Props) => {
  return <h2 className={styleClasses + props.className}>{props.title}</h2>;
};
