import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-white shadow dark:bg-dark border-t border-slate-200 dark:border-slate-700 ">
      <div className="p-4 md:p-6 ">
        <div className="text-sm text-gray-500 dark:text-gray-400 text-center">
          {"© 2024 "}
          <Link to="/" className="hover:underline">
            QBurst
          </Link>
          . All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};
