import { useLocation, useNavigate } from "react-router-dom";
import { Input, TextArea } from "../../components";
import { Button } from "../../components/elements/Button";
import { Loader } from "../../components/elements/Loader";
import { useState } from "react";
import EventService from "../../network/service/Event";
import { Event } from "../../model/Event";
import moment from "moment";
import { FileInput } from "../../components/elements/FileInput";

type FormData = {
  title: string;
  description: string;
  image: string;
  date: string;
};

export const AddEventPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const editEvent: Event | undefined = state;
  const eventDate = new Date(editEvent?.date ?? "");
  const [file, setFile] = useState<File>();
  const [formData, setFormData] = useState<FormData>({
    title: editEvent?.title ?? "",
    description: editEvent?.description ?? "",
    image: editEvent?.image ?? "",
    date: editEvent ? moment(eventDate).format("yyyy-MM-DDThh:mm") : "",
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const fd = new FormData();
    if (editEvent?.id != null) {
      fd.append("id", editEvent.id);
    }
    fd.append("title", formData.title);
    fd.append("description", formData.description);
    fd.append("date", formData.date);
    if (file != null) {
      fd.append("image", file);
    } else {
      fd.append("image", formData.image);
    }

    try {
      setLoading(true);
      await EventService.addEvent(fd);
      navigate("/");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <h2 className="text-center text-2xl font-medium tracking-tight text-gray-900 dark:text-white line-clamp-2 my-10">
        {editEvent ? "Update Event" : "Add Event"}
      </h2>
      <form className="w-full max-w-sm mx-auto" onSubmit={onSubmit}>
        <div className="mb-6">
          <Input
            label="Name"
            id="title"
            name="title"
            required
            placeholder="Cricket Tournament"
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />
        </div>
        <div className="mb-6">
          <TextArea
            rows={5}
            label="Description"
            id="description"
            name="description"
            required
            placeholder="Please enter description"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
        </div>
        <div className="mb-6">
          <FileInput
            accept="image/*"
            label="Image"
            id="image"
            name="image"
            type="file"
            required
            value={file ? file.name : editEvent?.image}
            onSelectFile={(e) => setFile(e ? e[0] : undefined)}
          />
        </div>
        <div className="mb-16">
          <Input
            label="Start Date"
            id="date"
            name="date"
            type="datetime-local"
            required
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          />
        </div>
        <Button type="submit" className="flex mx-auto">
          Submit
        </Button>
      </form>
      <Loader loading={loading} />
    </div>
  );
};
