import { Routes, Route } from "react-router-dom";
import {
  AddEmployeePage,
  AddTeamPage,
  AddEventPage,
  HomePage,
  PageNotFoundPage,
  LoginPage,
  EventDetailsPage,
} from "../pages";
import { ProtectedRoute } from "./ProtectedRoute";
import { AddPointsPage } from "../pages/admin/AddPointsPage";
import { ViewTeamsPage } from "../pages/team/ViewTeamsPage";
import { AddTechTopicPage } from "../pages/admin/AddTechTopicPage";
import { TechTopicDetailsPage } from "../pages/admin/TechTopicDetailsPage";
import PointsTableScreen from "../pages/points/PointsTableScreen";

export const AllRoutes = () => {
  return (
    <main className="relative md:p-6 dark:bg-dark p-4 overflow-auto flex flex-grow">
      <Routes>
        <Route path="/" element={<ProtectedRoute element={<HomePage />} />} />

        <Route
          path="/add-team"
          element={<ProtectedRoute element={<AddTeamPage />} />}
        />
        <Route
          path="/update-team"
          element={<ProtectedRoute element={<AddPointsPage />} />}
        />
        <Route
          path="/view-teams"
          element={<ProtectedRoute element={<ViewTeamsPage />} />}
        />

        <Route
          path="/add-employee"
          element={<ProtectedRoute element={<AddEmployeePage />} />}
        />

        <Route
          path="/add-event"
          element={<ProtectedRoute element={<AddEventPage />} />}
        />
        <Route
          path="/events/:id"
          element={<ProtectedRoute element={<EventDetailsPage />} />}
        />

        <Route
          path="/add-tech-topic"
          element={<ProtectedRoute element={<AddTechTopicPage />} />}
        />

        <Route
          path="/tech-topic/:id"
          element={<ProtectedRoute element={<TechTopicDetailsPage />} />}
        />

        <Route
          path="/points-table/:teamId"
          element={<ProtectedRoute element={<PointsTableScreen />} />}
        />

        <Route path="/login" element={<LoginPage />} />

        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
      <div
        id="loader"
        className="absolute left-0 top-0 right-0 bottom-0 hidden bg-black"
      ></div>
    </main>
  );
};

export default AllRoutes;
