import { ButtonHTMLAttributes } from "react";

type Props = {} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = (props: Props) => {
  const { className = "", ...rest } = props;
  const style = `text-base text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 rounded-lg px-16 py-2.5 font-medium ${className}`;
  return (
    <button {...rest} className={style}>
      {props.children}
    </button>
  );
};
