import { Link } from "react-router-dom";
import { Event } from "../../model/Event";
import moment from "moment";

type Props = { event: Event };

export const EventCard = (props: Props) => {
  const { title, description, image, id, date, points } = props.event;
  return (
    <Link
      to={`/events/${id}`}
      className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 hover:cursor-pointer"
    >
      <img
        className="rounded-t-lg object-cover w-full aspect-video"
        src={process.env.REACT_APP_BASE_URL + "api/" + image}
        alt=""
      />
      <div className="p-5">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">
          {title}
        </h5>
        {points && (
          <h1 className="mt-1 mb-2 text-base font-medium text-gray-900 dark:text-slate-200">
            {"Points: " + points}
          </h1>
        )}
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-2">
          {description}
        </p>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-2">
          {moment(date).format("MMM DD, yyyy [at] hh:mm A")}
        </p>
        <div className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Read more
          <svg
            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};
