import { ActivityIndicator } from "./ActivityIndicator";

type Props = { loading: boolean; mode?: "window" | "view" };

export const Loader = (props: Props) => {
  const { loading, mode = "window" } = props;

  if (!loading) return <></>;

  if (mode == "view") {
    return (
      <div className="flex flex-grow justify-center items-center content-center">
        <ActivityIndicator />
      </div>
    );
  }

  return (
    <div className="fixed left-0 top-0 right-0 bottom-0 bg-black/30 justify-center items-center content-center">
      <ActivityIndicator />
    </div>
  );
};
