import { toast } from "react-toastify";
import { EndPoints } from "../../constants/EndPoints";
import APIManager from "../APIManager";
import User from "../../model/User";

export default class UserService {
  static login = async (params: Record<string, any>) => {
    try {
      const response = await APIManager.makeRequest<User>({
        endPoint: "api/" + EndPoints.login,
        method: "POST",
        body: params,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static getUsers = async () => {
    try {
      const response = await APIManager.makeRequest<User[]>({
        endPoint: "api/" + EndPoints.getUsers,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static addEmployee = async (params: Record<string, any>) => {
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.addUser,
        method: "POST",
        body: params,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static deleteEmployee = async (userId: string) => {
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.deleteUser + `/${userId}`,
        method: "DELETE",
      });
      toast(response.message, { type: "success" });
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };
}
