import { useLocation, useNavigate } from "react-router-dom";
import { Input, TextArea } from "../../components";
import { Button } from "../../components/elements/Button";
import { Loader } from "../../components/elements/Loader";
import { useState } from "react";
import TeamService from "../../network/service/Team";
import Team from "../../model/Team";
import { FileInput } from "../../components/elements/FileInput";
import { useAppSelector } from "../../store/Hooks";

export const AddTeamPage = () => {
  const navigate = useNavigate();
  const role = useAppSelector((state) => state.app.user?.role);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [file, setFile] = useState<File>();
  const editTeam: Team | undefined = state;
  const [formData, setFormData] = useState({
    name: editTeam?.name ?? "",
    description: editTeam?.description ?? "",
    logo: editTeam?.logo ?? "",
  });

  const onDeleteHandler = async () => {
    if (!editTeam) return;
    try {
      setLoading(true);
      await TeamService.deleteTeam(editTeam.id);
      navigate("/");
    } catch (error) {}
    setLoading(false);
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const fd = new FormData();
    if (editTeam?.id != null) {
      fd.append("id", editTeam.id);
    }
    fd.append("name", formData.name);
    fd.append("description", formData.description);
    if (file != null) {
      fd.append("logo", file);
    } else {
      fd.append("logo", formData.logo);
    }

    try {
      setLoading(true);
      await TeamService.addTeam(fd);
      navigate("/");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <h2 className="text-center text-2xl font-medium tracking-tight text-gray-900 dark:text-white line-clamp-2 my-10">
        {editTeam ? "Update Team" : "Add Team"}
      </h2>
      <form className="w-full max-w-sm mx-auto" onSubmit={onSubmit}>
        <div className="mb-6">
          <Input
            label="Name"
            id="name"
            name="name"
            required
            placeholder="Tech Titans"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>
        <div className="mb-6">
          <TextArea
            rows={5}
            label="Description"
            id="description"
            name="description"
            required
            placeholder="Please enter description"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
        </div>
        <div className="mb-16">
          <FileInput
            accept="image/*"
            label="Team Logo"
            id="logo"
            name="logo"
            type="file"
            required
            value={file ? file.name : editTeam?.logo}
            onSelectFile={(e) => setFile(e ? e[0] : undefined)}
          />
        </div>
        <div className="flex">
          <Button type="submit" className="flex mx-auto">
            Submit
          </Button>
          {role == "admin" && editTeam && (
            <button
              type="button"
              className="px-16 py-2.5 flex mx-auto items-center text-lg font-medium text-center text-white rounded-lg bg-red-600 hover:bg-red-700"
              onClick={() => onDeleteHandler()}
            >
              Delete
            </button>
          )}
        </div>
        <Loader loading={loading} />
      </form>
    </div>
  );
};
