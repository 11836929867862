import NotFound from "../../../src/assets/svg/not_found.svg";

type Props = { message?: string };

export const NotAvailable = (props: Props) => {
  const { message } = props;
  return (
    <div className="flex flex-col items-center justify-center flex-grow min-h-96">
      <img src={NotFound} className="max-w-48 mb-2" />
      <span className="text-lg font-normal text-gray-900 dark:text-gray-400 text-center">
        {message ? message : "Data not available"}
      </span>
    </div>
  );
};
