import { toast } from "react-toastify";
import { EndPoints } from "../../constants/EndPoints";
import Team from "../../model/Team";
import APIManager from "../APIManager";
import { Event } from "../../model/Event";
import { TechTopic } from "../../model/TechTopic";
import { Points } from "../../model/Points";

export default class PointsService {
  static fetchDetails = async () => {
    try {
      const responseTeams = await APIManager.makeRequest<Team[]>({
        endPoint: "api/" + EndPoints.getTeams,
      });
      const responseEvents = await APIManager.makeRequest<Event[]>({
        endPoint: "api/" + EndPoints.getEvents + `?type=passed`,
      });
      const responseTechEvents = await APIManager.makeRequest<TechTopic[]>({
        endPoint: "api/" + EndPoints.getTechTopics,
      });
      if (responseTeams.data.length == 0) {
        throw new Error("Add teams before adding details");
      }
      if (responseEvents.data.length == 0) {
        throw new Error("No passed events found");
      }
      return {
        teams: responseTeams.data,
        events: responseEvents.data,
        techTopics: responseTechEvents.data,
      };
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static addPoints = async (
    teamId: string,
    eventId: string,
    value: number,
    remark: string,
    id?: string
  ) => {
    const params: Record<string, any> = { teamId, eventId, value, remark };
    if (id) {
      params["id"] = id;
    }
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.addPoints,
        method: "POST",
        body: params,
      });
      toast("Points updated successfully", { type: "success" });
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static getPoints = async (teamId: string) => {
    try {
      const points = await APIManager.makeRequest<Points[]>({
        endPoint: "api/" + EndPoints.getPoints + teamId,
      });
      return points.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };
}
