import React, { InputHTMLAttributes, useRef } from "react";

type Props = {
  id: string;
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = (props: Props) => {
  const { label, id, ...rest } = props;

  const symbol = rest.required ? "*" : "";

  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {label}
        <label className="ml-1 font-medium text-red-600">{symbol}</label>
      </label>
      <input
        id={id}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        {...rest}
      />
    </div>
  );
};
