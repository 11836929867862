import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { useEffect, useState } from "react";
import {
  DropDownViewItem,
  PopoverListGroup,
} from "../elements/PopoverListGroup";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { updateToken, updateUser } from "../../store/slices/AppSlice";

const ADMIN_OPTIONS: DropDownViewItem[] = [
  { title: "Add Team", isLink: true, to: "/add-team" },
  {
    title: "Add Employee",
    isLink: true,
    to: "/add-employee",
  },
  { title: "Add Event", isLink: true, to: "/add-event" },
  { title: "Add Tech/Cultural Event", isLink: true, to: "/add-tech-topic" },
  { title: "Add Points", isLink: true, to: "/update-team" },
  { title: "View Teams", isLink: true, to: "/view-teams" },
  // { title: "Points Table", isLink: true, to: "/points-table" },
];

export const Header = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.app.user);
  const dispatch = useAppDispatch();
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkMode") ?? "false") || false
  );

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));

    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const handleDarkMode = () => setDarkMode(!darkMode);

  const handleLogout = () => {
    dispatch(updateToken(undefined));
    dispatch(updateUser(undefined));
    navigate("/login");
  };

  const profileOptions: DropDownViewItem[] = user
    ? [{ title: "Logout", isLink: false, action: handleLogout }]
    : [];

  return (
    <header>
      <nav className="bg-white dark:bg-gray-900">
        <div className="border-b border-slate-200 dark:border-slate-700 flex flex-wrap justify-between items-center px-4 py-3">
          <Link to="/" className="flex items-cente">
            <h3 className="cursor-pointer font-medium text-2xl text-dark dark:text-white">
              QBurst
            </h3>
            {/* <img
              src={"/assets/images/qb_logo.png"}
              className="mr-3 h-10"
              alt="CodeBook Logo"
            /> */}
          </Link>
          <div className="flex items-center relative">
            <span
              onClick={handleDarkMode}
              className="cursor-pointer text-2xl text-dark dark:text-white mr-5 bi bi-brightness-high-fill"
            />
            {user && (
              <div className="mr-5">
                <PopoverListGroup
                  items={
                    user.role == "admin"
                      ? ADMIN_OPTIONS
                      : [ADMIN_OPTIONS[ADMIN_OPTIONS.length - 1]]
                  }
                >
                  <i className="bi bi-person-plus-fill cursor-pointer text-2xl text-dark dark:text-white"></i>
                </PopoverListGroup>
              </div>
            )}
            <PopoverListGroup items={profileOptions}>
              <i className="bi bi-person-circle cursor-pointer text-2xl text-dark dark:text-white"></i>
            </PopoverListGroup>
          </div>
        </div>
      </nav>
    </header>
  );
};
