import React from "react";

type Props = {
  children?: any | any[];
} & React.HTMLAttributes<HTMLElement>;

const styleClasses = "my-6 bg-gray-100/80 dark:bg-gray-900 rounded-xl p-8 ";

export const Section = (props: Props) => {
  return <div className={styleClasses + props.className}>{props.children}</div>;
};
