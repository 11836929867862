// LeaderboardEntry.jsx

import { useNavigate } from "react-router-dom";
import Team from "../../model/Team";
import { useAppSelector } from "../../store/Hooks";

type Props = { team: Team; showBadge: boolean };

const LeaderboardEntry = (props: Props) => {
  const role = useAppSelector((state) => state.app.user?.role);
  const { team, showBadge } = props;
  const navigate = useNavigate();

  const onItemPress = () => {
    // if (role == "admin") {
    //   navigate("/add-team", { state: team });
    // }
    navigate(`/points-table/${team.id}`);
  };

  return (
    <div
      className="flex items-center justify-between px-6 py-3 mb-2 rounded-md border border-gray-200 bg-white dark:bg-dark dark:border-gray-600 hover:cursor-pointer"
      onClick={() => onItemPress()}
    >
      <div className="flex flex-row items-center">
        <img
          src={process.env.REACT_APP_BASE_URL + "api/" + team.logo}
          className="w-10 h-10 object-cover mr-5 border rounded border-slate-300 dark:border-slate-100"
        />
        <span className="text-xl font-medium text-gray-900 dark:text-white">
          {team.name}
        </span>
      </div>
      <div>
        {showBadge && (
          <i className="text-lg bi bi-trophy-fill text-gray-900 dark:text-white mr-2"></i>
        )}
        <span className="text-xl font-medium text-gray-900 dark:text-white">
          {team.points}
        </span>
      </div>
    </div>
  );
};

export default LeaderboardEntry;
