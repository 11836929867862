import { toast } from "react-toastify";
import { EndPoints } from "../../constants/EndPoints";
import APIManager from "../APIManager";
import { TechTopic, TechTopicType } from "../../model/TechTopic";

export default class TechTopicService {
  static fetchTechTopicDetails = async (id: string) => {
    try {
      const response = await APIManager.makeRequest<TechTopic>({
        endPoint: "api/" + EndPoints.getTechTopics + `${id}`,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static fetchTechTopics = async (date: string, type: TechTopicType) => {
    try {
      const response = await APIManager.makeRequest<TechTopic[]>({
        endPoint:
          "api/" + EndPoints.getTechTopics + `?type=${type}&date=${date}`,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static addTechTopic = async (params: Record<string, any>) => {
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.addTechTopic,
        method: "POST",
        body: params,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static deleteTechTopic = async (eventId: string) => {
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.deleteTechTopic + `/${eventId}`,
        method: "DELETE",
      });
      toast(response.message, { type: "success" });
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };
}
