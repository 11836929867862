import React, { InputHTMLAttributes, useRef } from "react";

type Props = {
  id: string;
  label: string;
  onSelectFile: (files: FileList | null) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const FileInput = (props: Props) => {
  const { label, id, value, onSelectFile, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const symbol = rest.required ? "*" : "";

  const onItemPress = () => {
    inputRef.current?.click();
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {label}
        <label className="ml-1 font-medium text-red-600">{symbol}</label>
      </label>
      <div
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-11 hover:cursor-pointer"
        onClick={() => onItemPress()}
      >
        {!value && (
          <span className="border-gray-300 text-sm rounded-lg text-gray-400">
            Please select file
          </span>
        )}
        {typeof value == "string" && value.length > 0 && (
          <span className="border-gray-300 text-sm rounded-lg text-gray-900 dark:text-white line-clamp-1">
            {value}
          </span>
        )}
      </div>
      <input
        ref={inputRef}
        id={id}
        type={"file"}
        style={{ display: "none" }}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12"
        {...rest}
        onChange={(e) => onSelectFile(e.target.files)}
        required={false}
      />
    </div>
  );
};
