import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ScrollToTop } from "./components";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./store/Store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId="93212067630-3eje3upsvqukdp3572tomoqrmm42di8d.apps.googleusercontent.com">
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <ToastContainer
          closeButton={false}
          autoClose={3000}
          position="bottom-right"
        />
        <App />
      </Router>
    </Provider>
  </GoogleOAuthProvider>
);
