import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { Button } from "../../components/elements/Button";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { updateToken, updateUser } from "../../store/slices/AppSlice";
import UserService from "../../network/service/User";
import { Loader } from "../../components/elements/Loader";
import { toast } from "react-toastify";

export const LoginPage = () => {
  useTitle("Login");
  const [loading, setLoading] = useState(false);
  const { token } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        setLoading(true);
        const data = await UserService.login({
          token: codeResponse.access_token,
        });
        dispatch(updateUser(data));
        dispatch(updateToken(data.access_token));
        navigate("/");
      } catch (error) {}
      setLoading(false);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const logOut = () => {
    googleLogout();
    dispatch(updateUser(undefined));
    dispatch(updateToken(undefined));
  };

  return (
    <div className="flex flex-grow justify-center items-center">
      <Button onClick={() => (token ? logOut() : login())}>
        {!token ? "Sign in with Google" : "Logout"}
      </Button>
      <Loader loading={loading} />
    </div>
  );
};
