import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Input, InputPicker } from "../../components";
import Team from "../../model/Team";
import { Button } from "../../components/elements/Button";
import { toast } from "react-toastify";
import { Loader } from "../../components/elements/Loader";
import { useEffect, useState } from "react";
import UserService from "../../network/service/User";
import TeamService from "../../network/service/Team";
import User from "../../model/User";
import { useAppSelector } from "../../store/Hooks";

export const AddEmployeePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<Team[]>();
  const { state } = useLocation();
  const editUser: User | undefined = state;
  const role = useAppSelector((state) => state.app.user?.role);

  const [formData, setFormData] = useState({
    name: editUser?.name ?? "",
    email: editUser?.email ?? "",
    employeeId: editUser?.employeeId ?? "",
    role: editUser?.role ?? "",
    teamId: editUser?.teamId ?? "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const t = await TeamService.fetchTeams();
        setTeams(t.sort((a, b) => b.points - a.points));
      } catch (error) {
        navigate("../");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const teamItems = (teams ?? []).map((team, index) => ({
    title: team.name,
    value: team.id,
  }));

  if (teams && teams.length == 0) {
    toast("Add teams before adding details", { type: "warning" });
    return <Navigate to="/add-team" />;
  }

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const fd: Record<string, any> = { ...formData };
    if (editUser?.id != null) {
      fd["id"] = editUser.id;
    }

    try {
      setLoading(true);
      await UserService.addEmployee(fd);
      navigate("/");
    } catch (error) {}
    setLoading(false);
  };

  const onDeleteHandler = async () => {
    if (!editUser) return;
    try {
      setLoading(true);
      await UserService.deleteEmployee(editUser.id);
      navigate("/");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <h2 className="text-center text-2xl font-medium tracking-tight text-gray-900 dark:text-white line-clamp-2 my-10">
        {editUser ? "Update Employee" : "Add Employee"}
      </h2>
      <form className="w-full max-w-sm mx-auto" onSubmit={onSubmit}>
        <div className="mb-6">
          <Input
            label="Employee Id"
            id="id"
            name="employeeId"
            required
            type="number"
            placeholder="1234"
            value={formData.employeeId}
            onChange={(e) =>
              setFormData({ ...formData, employeeId: e.target.value })
            }
          />
        </div>
        <div className="mb-6">
          <Input
            label="Full Name"
            id="name"
            name="name"
            required
            placeholder="John Doe"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>
        <div className="mb-6">
          <Input
            label="Email"
            id="email"
            name="email"
            type="email"
            required
            placeholder="name@qburst.com"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </div>
        <div className="flex mb-16">
          <div className="flex-grow">
            <InputPicker
              label="Role"
              id="role"
              name="role"
              required
              items={[
                { title: "Admin", value: "admin" },
                { title: "Employee", value: "employee" },
              ]}
              value={formData.role}
              onChange={(e) =>
                setFormData({ ...formData, role: e.target.value })
              }
            />
          </div>
          <div className="w-8" />
          <div className="flex-grow">
            <InputPicker
              label="Team"
              id="teamId"
              name="teamId"
              required
              items={teamItems}
              value={formData.teamId}
              onChange={(e) =>
                setFormData({ ...formData, teamId: e.target.value })
              }
            />
          </div>
        </div>
        <div className="flex">
          <Button type="submit" className="flex mx-auto">
            Submit
          </Button>
          {role == "admin" && editUser && (
            <button
              type="button"
              className="px-16 py-2.5 flex mx-auto items-center text-lg font-medium text-center text-white rounded-lg bg-red-600 hover:bg-red-700"
              onClick={() => onDeleteHandler()}
            >
              Delete
            </button>
          )}
        </div>
      </form>
      <Loader loading={loading} />
    </div>
  );
};
