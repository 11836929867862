import { toast } from "react-toastify";
import { EndPoints } from "../../constants/EndPoints";
import APIManager from "../APIManager";
import Team from "../../model/Team";

export default class TeamService {
  static fetchTeams = async () => {
    try {
      const response = await APIManager.makeRequest<Team[]>({
        endPoint: "api/" + EndPoints.getTeams,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static addTeam = async (params: Record<string, any>) => {
    try {
      const response = await APIManager.makeRequest<Team[]>({
        endPoint: "api/" + EndPoints.addTeam,
        method: "POST",
        body: params,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static deleteTeam = async (teamId: string) => {
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.deleteTeams + `/${teamId}`,
        method: "DELETE",
      });
      toast(response.message, { type: "success" });
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };
}
