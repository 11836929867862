import React, { useEffect, useMemo, useState } from "react";
import { Input, InputPicker, TextArea } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import Team from "../../model/Team";
import { Loader } from "../../components/elements/Loader";
import { Button } from "../../components/elements/Button";
import PointsService from "../../network/service/Points";
import { Event } from "../../model/Event";
import { Points } from "../../model/Points";

type EventItem = Omit<Event, "image" | "participants">;

export const AddPointsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const editPoint: Points | undefined = state;
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<Team[]>();
  const [events, setEvents] = useState<EventItem[]>();
  const [formData, setFormData] = useState({
    remark: editPoint?.remark ?? "",
    point: editPoint?.value.toString() ?? "",
    eventId: editPoint?.event.id ?? "",
    teamId: editPoint?.team.id ?? "",
  });

  const teamItems = useMemo(() => {
    return (teams ?? []).map((team, index) => ({
      title: team.name,
      value: team.id,
    }));
  }, [teams]);

  const eventItems = useMemo(() => {
    return (events ?? []).map((event, index) => ({
      title: event.title,
      value: event.id,
    }));
  }, [events]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await PointsService.fetchDetails();
        setTeams(res.teams);
        setEvents([...res.events, ...res.techTopics]);
      } catch (error) {
        navigate("../");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const fd = new FormData(event.target as HTMLFormElement);
    const teamId = fd.get("teamId") as string;
    const eventId = fd.get("eventId") as string;
    const points = +(fd.get("points") ?? "0");
    const remark = fd.get("remark") as string;

    try {
      setLoading(true);
      await PointsService.addPoints(
        teamId,
        eventId,
        points,
        remark,
        editPoint?.id
      );
      navigate("/");
    } catch {}
    setLoading(false);
  };

  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <h2 className="text-center text-3xl font-medium tracking-tight text-gray-900 dark:text-white line-clamp-2 mb-12">
        {editPoint ? "Edit Point" : "Add Points"}
      </h2>
      <form className="w-full max-w-sm mx-auto" onSubmit={onSubmit}>
        <div className="flex-grow mb-6">
          <InputPicker
            label="Team"
            id="teamId"
            name="teamId"
            required
            items={teamItems}
            value={formData.teamId}
            onChange={(e) =>
              setFormData({ ...formData, teamId: e.target.value })
            }
          />
        </div>
        <div className="flex-grow mb-6">
          <InputPicker
            label="Event"
            id="eventId"
            name="eventId"
            required
            items={eventItems}
            value={formData.eventId}
            onChange={(e) =>
              setFormData({ ...formData, eventId: e.target.value })
            }
          />
        </div>
        <div className="flex-grow mb-6">
          <Input
            label="Points"
            id="points"
            name="points"
            type="number"
            placeholder="20"
            min={0.0}
            step={0.1}
            required
            value={formData.point}
            onChange={(e) =>
              setFormData({ ...formData, point: e.target.value })
            }
          />
        </div>
        <div className="mb-16">
          <TextArea
            rows={5}
            label="Remark"
            id="remark"
            name="remark"
            required
            placeholder="Please enter remark"
            value={formData.remark}
            onChange={(e) =>
              setFormData({ ...formData, remark: e.target.value })
            }
          />
        </div>
        <Button type="submit" className="flex mx-auto">
          {editPoint ? "Update" : "Submit"}
        </Button>
      </form>
      <Loader loading={loading} />
    </div>
  );
};
