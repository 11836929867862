import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import { Loader } from "../../components/elements/Loader";
import { Section } from "../../components/elements/Section";
import { TechTopic } from "../../model/TechTopic";
import TechTopicService from "../../network/service/TechTopics";
import { useAppSelector } from "../../store/Hooks";
import { SectionTitle } from "../../components/elements/SectionTitle";
import { UserListItem } from "../../components/elements/UserListItem";
import { NotAvailable } from "../../components/elements/NotAvailable";
import Linkify from "linkify-react";
import { Button } from "../../components/elements/Button";

export const TechTopicDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [techTopic, setTechTopic] = useState<TechTopic>();
  const user = useAppSelector((state) => state.app.user);

  const fetchData = async () => {
    try {
      setLoading(true);
      const e = await TechTopicService.fetchTechTopicDetails(id!);
      console.log(e);
      setTechTopic(e);
    } catch (error) {
      navigate("../");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onDeleteHandler = async () => {
    try {
      setLoading(true);
      await TechTopicService.deleteTechTopic(id ?? "");
      navigate("../");
    } catch (error) {}
    setLoading(false);
  };

  const onEditHandler = () => {
    navigate("/add-tech-topic", { state: techTopic });
  };

  const renderLink = ({ attributes, content }: any) => {
    const { href, ...props } = attributes;
    return (
      <Link className="text-blue-500 font-medium" to={href} {...props}>
        {content}
      </Link>
    );
  };
  const fileURL = `${
    process.env.REACT_APP_BASE_URL + "api/" + techTopic?.file
  }`;

  return (
    <div className="flex flex-grow flex-col">
      {techTopic && (
        <Section className="flex">
          <div className="flex flex-grow flex-col">
            <div className="flex flex-row flex-grow items-center justify-between mb-8">
              <h1 className="text-4xl font-semibold text-gray-900 dark:text-slate-200 mr-10">
                {techTopic.title}
              </h1>
              <h5 className="text-lg text-gray-900 dark:text-slate-200 ml-auto">
                {moment(techTopic.date).format("MMMM yyyy")}
              </h5>
            </div>
            {techTopic.points && (
              <h1 className="mb-5 text-2xl font-medium text-gray-900 dark:text-slate-200">
                {"Points: " + techTopic.points}
              </h1>
            )}
            <Linkify options={{ render: renderLink }}>
              <p className="mb-5 text-base sm:text-lg text-gray-900 dark:text-slate-200 whitespace-pre-wrap">
                {techTopic.description}
              </p>
            </Linkify>

            <div className="flex justify-between mt-10">
              <div>
                {techTopic.file && techTopic.file.length > 0 && (
                  <a href={fileURL} download>
                    <Button>Download</Button>
                  </a>
                )}
              </div>
              {user && user.role == "admin" && (
                <div className="mt-1">
                  <button onClick={() => onEditHandler()}>
                    <i className="mr-4 bi bi-pencil-square cursor-pointer text-3xl text-dark dark:text-white"></i>
                  </button>
                  <button onClick={onDeleteHandler}>
                    <i className="bi bi-trash cursor-pointer text-3xl text-red-500"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </Section>
      )}
      <Section>
        <SectionTitle title="Presenters" />
        <ul className="px-8">
          {techTopic?.presentors.map((p, i) => {
            return (
              <UserListItem
                key={p.id}
                user={p}
                className="bg-white dark:bg-dark"
              />
            );
          })}
        </ul>
        {techTopic && techTopic.presentors.length == 0 && (
          <NotAvailable message="No presenters added yet" />
        )}
      </Section>
      <Loader loading={loading} />
    </div>
  );
};
