import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/Hooks";

type Props = {
  element: React.ReactElement;
};

export const ProtectedRoute = (props: Props) => {
  const token = useAppSelector((state) => state.app.token);
  if (!token) {
    return <Navigate to="/login" />;
  }
  return props.element;
};
