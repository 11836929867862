import { AllRoutes } from "./routes/AllRoutes";
import { Footer, Header } from "./components";
import { useEffect, useState } from "react";
import LocalStorage from "./storage/LocalStorage";
import { useAppDispatch } from "./store/Hooks";
import { updateToken, updateUser } from "./store/slices/AppSlice";
import { Loader } from "./components/elements/Loader";

function App() {
  const [isReady, setIsReady] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const token = LocalStorage.getItem("token");
    const user = LocalStorage.getItem("user");
    if (token != null && user != null) {
      dispatch(updateToken(token));
      dispatch(updateUser(JSON.parse(user)));
    }
    setIsReady(true);
  }, []);

  if (!isReady) return <Loader loading={true} />;

  return (
    <div className="app">
      <Header />
      <AllRoutes />
      <Footer />
    </div>
  );
}

export default App;
