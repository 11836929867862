import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/Hooks";
import "./PointsTableScreen.css";
import { useNavigate, useParams } from "react-router-dom";
import PointsService from "../../network/service/Points";
import { Points } from "../../model/Points";
import { Loader } from "../../components/elements/Loader";
import moment from "moment";
import { NotAvailable } from "../../components/elements/NotAvailable";

const PointsTableScreen = () => {
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState<Points[]>([]);
  const [apiLoaded, setApiLoaded] = useState(false);
  const user = useAppSelector((state) => state.app.user);

  const fetchPoints = async () => {
    if (!teamId) return;
    try {
      setLoading(true);
      const res = await PointsService.getPoints(teamId);
      setPoints(res.sort((a, b) => b.value - a.value));
      setApiLoaded(true);
    } catch (error) {
      navigate(-1);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPoints();
  }, []);

  const data = [
    { name: "John Doe", points: 50 },
    { name: "Jane Smith", points: 75 },
    { name: "Bob Johnson", points: 60 },
  ];

  if (!apiLoaded) return <Loader loading={loading} />;

  return (
    <div className="overflow-x-auto w-full">
      {points && points.length > 0 && (
        <table className="w-full bg-white border-collapse border">
          <thead className="">
            <tr className="bg-gray-300 border border-gray-300 py-2 px-4">
              <th className="py-2 px-4 font-semibold">S.No.</th>
              <th className="py-2 px-4 font-semibold">Event</th>
              <th className="py-2 px-4 font-semibold">Representer</th>
              <th className="py-2 px-4 font-semibold">Date</th>
              <th className="py-2 px-4 font-semibold">Points</th>
              {user?.role == "admin" && (
                <th className="py-2 px-4 font-semibold"></th>
              )}
            </tr>
          </thead>
          <tbody>
            {points.map((e, i) => (
              <tr
                key={e.id}
                className="px-4 py-2 text-sm border border-gray-300 bg-gray-100/80"
              >
                <td className="w-3">{i + 1}</td>
                <td className="">{e.event.title}</td>
                <td className="">
                  {e.presentors.length > 0
                    ? e.presentors.map((e) => e.name).join(", ")
                    : "Noida Team"}
                </td>
                <td className="w-36">
                  {e.created_at
                    ? moment(e.created_at).format("MMM DD, yyyy")
                    : "N/A"}
                </td>
                <td className="w-10">{e.value}</td>
                {user?.role == "admin" && (
                  <td className="w-14">
                    <button
                      onClick={() => navigate("/update-team", { state: e })}
                    >
                      <i className="bi bi-pencil-square cursor-pointer text-2xl text-dark"></i>
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {points && points.length == 0 && (
        <NotAvailable message="Points not available" />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default PointsTableScreen;
