import { Link } from "react-router-dom";
import { TechTopic } from "../../model/TechTopic";
import moment from "moment";

type Props = { item: TechTopic };

export const TechTopicCard = (props: Props) => {
  const { title, description, date, id, points } = props.item;
  return (
    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col flex-grow w-full">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        {title}
      </h5>
      {points && (
        <h1 className="mb-2 text-base font-medium text-gray-900 dark:text-slate-200">
          {"Points: " + points}
        </h1>
      )}
      <div className="flex flex-col flex-grow justify-between">
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-4 whitespace-pre-wrap">
          {description}
        </p>
        <div className="flex items-center justify-between">
          <Link
            to={`/tech-topic/${id}`}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Link>
          <p className="font-semibold text-gray-700 dark:text-gray-400 line-clamp-4">
            {moment(new Date(date)).format("MMMM yyyy").toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
};
