import { Navigate, useNavigate } from "react-router-dom";
import Team from "../../model/Team";
import { toast } from "react-toastify";
import { InputPicker } from "../../components";
import { useEffect, useState } from "react";
import { UserListItem } from "../../components/elements/UserListItem";
import { Loader } from "../../components/elements/Loader";
import { NotAvailable } from "../../components/elements/NotAvailable";
import TeamService from "../../network/service/Team";
import UserService from "../../network/service/User";
import User from "../../model/User";
import { Button } from "../../components/elements/Button";

export const ViewTeamsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<Team[]>();
  const [selectedTeamId, setSelectedTeamId] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const t = await TeamService.fetchTeams();
        setTeams(t.sort((a, b) => b.points - a.points));
      } catch (error) {
        navigate("../");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const teamItems = (teams ?? []).map((team, index) => ({
    title: team.name,
    value: team.id,
  }));

  useEffect(() => {
    if (!teams || teams.length == 0) return;
    setSelectedTeamId(teams[0].id);
  }, [teams]);

  if (teams && teams.length == 0) {
    toast("Add teams before adding details", { type: "warning" });
    return <Navigate to="/add-team" />;
  }

  const onChangeTeam = (teamId: string) => {
    setSelectedTeamId(teamId);
  };

  const team = teams?.find((team) => team.id == selectedTeamId);
  const users = team?.users ?? [];

  const onDeleteHandler = async (id: string) => {
    try {
      setLoading(true);
      const result = await UserService.deleteEmployee(id);
      if (!teams) return;
      const updatedTeams = [...teams];
      const teamIndex = teams.findIndex((team) => team.id == selectedTeamId);
      if (teamIndex != -1) {
        const team = updatedTeams[teamIndex];
        team.users = team.users.filter((e) => e.id != id);
        updatedTeams[teamIndex] = { ...team };
      }
      setTeams(updatedTeams);
    } catch (error) {}
    setLoading(false);
  };

  const onEditHandler = async (user: User) => {
    navigate("/add-employee", { state: user });
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="mb-8 flex flex-row justify-center">
        <div className="flex flex-grow" />
        <InputPicker
          className="w-1/4"
          id="teamId"
          name="teamId"
          required
          items={teamItems}
          onChange={(e) => onChangeTeam(e.target.value)}
        />
        <div className="w-5" />
        <div className="flex flex-grow" />
        <Button
          className="px-8"
          onClick={() => navigate(`/points-table/${selectedTeamId}`)}
        >
          Points Table
        </Button>
      </div>
      {users &&
        users.map((user) => (
          <UserListItem
            key={user.id}
            user={user}
            onDelete={onDeleteHandler.bind(this, user.id)}
            onEdit={onEditHandler.bind(this, user)}
          />
        ))}
      {users && users.length == 0 && (
        <NotAvailable message="No members found" />
      )}
      <Loader loading={loading} />
    </div>
  );
};
