import { toast } from "react-toastify";
import { EndPoints } from "../../constants/EndPoints";
import APIManager from "../APIManager";
import { Event } from "../../model/Event";

export default class EventService {
  static joinEvent = async (eventId: string, userId: string) => {
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.joinEvent,
        method: "POST",
        body: { eventId, userId },
      });
      toast(response.message, { type: "success" });
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static fetchEventDetails = async (eventId: string) => {
    try {
      const response = await APIManager.makeRequest<Event>({
        endPoint: "api/" + EndPoints.getEvents + `/${eventId}`,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static fetchEvents = async (type: "upcoming" | "passed") => {
    try {
      const response = await APIManager.makeRequest<Event[]>({
        endPoint: "api/" + EndPoints.getEvents + `?type=${type}`,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static addEvent = async (params: Record<string, any>) => {
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.addEvent,
        method: "POST",
        body: params,
      });
      return response.data;
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };

  static deleteEvent = async (eventId: string) => {
    try {
      const response = await APIManager.makeRequest({
        endPoint: "api/" + EndPoints.deleteEvent + `/${eventId}`,
        method: "DELETE",
      });
      toast(response.message, { type: "success" });
    } catch (error) {
      toast((error as Error).message, { type: "error" });
      throw error;
    }
  };
}
