import { useState } from "react";
import User from "../../model/User";
import { useAppSelector } from "../../store/Hooks";
import { Loader } from "./Loader";

type Props = {
  user: User;
  onDelete?: () => void;
  onEdit?: () => void;
} & React.HTMLAttributes<HTMLElement>;

export const UserListItem = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const role = useAppSelector((state) => state.app.user?.role);
  const { name, employeeId, email } = props.user;

  let styleClasses =
    "flex items-center justify-between px-6 py-3 mb-2 rounded-md border border-gray-200 bg-gray-100/80 dark:bg-gray-900 dark:border-gray-600 " +
    props.className;
  if (props.className) {
    styleClasses =
      "flex items-center justify-between px-6 py-3 mb-2 rounded-md border border-gray-200 dark:border-gray-600 " +
      props.className;
  }

  return (
    <div className={styleClasses}>
      <div className="flex flex-col">
        <span className="text-lg font-semibold text-gray-900 dark:text-white capitalize">
          {name}
        </span>
        <span className="text-md font-medium text-gray-500 dark:text-slate-400 lowercase">
          {email}
        </span>
      </div>
      {role && role == "admin" && props.onDelete && (
        <div className="flex flex-col items-end">
          <span className="text-xl font-bold text-gray-900 dark:text-white">
            {employeeId}
          </span>
          <div className="mt-1">
            {props.onEdit && (
              <button onClick={() => props.onEdit?.()}>
                <i className="mr-2 bi bi-pencil-square cursor-pointer text-2xl text-dark dark:text-white"></i>
              </button>
            )}
            <button onClick={() => props.onDelete?.()}>
              <i className="bi bi-trash cursor-pointer text-2xl text-red-500"></i>
            </button>
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </div>
  );
};
