import { EventList } from "../../components/elements/EventList";
import Leaderboard from "../../components/elements/LeaderBoard";
import { Section } from "../../components/elements/Section";
import { SectionTitle } from "../../components/elements/SectionTitle";
import { TechTopicList } from "../../components/elements/TechTopicList";
import { useTitle } from "../../hooks/useTitle";

export const HomePage = () => {
  useTitle("Home");
  return (
    <div className="flex flex-col flex-grow">
      <Section>
        <SectionTitle title="Leaderboard" />
        <Leaderboard />
      </Section>
      <Section>
        <SectionTitle title="Tech Events" className="highlighted-text" />
        <TechTopicList type="tech" />
      </Section>
      <Section>
        <SectionTitle title="Cultural Events" className="highlighted-text" />
        <TechTopicList type="cultural" />
      </Section>
      <Section>
        <SectionTitle title="Upcoming Events" className="highlighted-text" />
        <EventList type="upcoming" />
      </Section>
      <Section>
        <SectionTitle title="History Events" />
        <EventList type="passed" />
      </Section>
    </div>
  );
};
