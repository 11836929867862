import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Slices } from "../Slices";
import User from "../../model/User";
import LocalStorage from "../../storage/LocalStorage";

interface AppSliceState {
  user: User | undefined;
  token: string | undefined;
}

const initialState: AppSliceState = {
  user: undefined,
  token: undefined,
};

export const appSlice = createSlice({
  name: Slices.app,
  initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload != null) {
        LocalStorage.setItem("token", action.payload);
      } else {
        LocalStorage.removeItem("token");
      }
      state.token = action.payload;
    },
    updateUser: (state, action: PayloadAction<User | undefined>) => {
      if (action.payload != null) {
        LocalStorage.setItem("user", JSON.stringify(action.payload));
      } else {
        LocalStorage.removeItem("user");
      }
      state.user = action.payload;
    },
    clearAppSlice: () => initialState,
  },
});

export const { updateUser, updateToken, clearAppSlice } = appSlice.actions;
export default appSlice.reducer;
