// Leaderboard.jsx
import { useState, useEffect } from "react";
import LeaderboardEntry from "./LeaderBoardEntry";
import Team from "../../model/Team";
import { Loader } from "./Loader";
import { NotAvailable } from "./NotAvailable";
import TeamService from "../../network/service/Team";
import { useNavigate } from "react-router-dom";

const Leaderboard = () => {
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<Team[]>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const t = await TeamService.fetchTeams();
        setTeams(t.sort((a, b) => b.points - a.points));
      } catch (error) {
        navigate("../");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="container mx-auto max-w-xl min-h-48 flex flex-col">
      {teams && (
        <div>
          {teams.map((team, index) => (
            <LeaderboardEntry key={index} team={team} showBadge={index == 0} />
          ))}
        </div>
      )}
      {!teams && <Loader loading={true} mode="view" />}
      {teams && teams.length == 0 && (
        <NotAvailable message="Teams not available" />
      )}
    </div>
  );
};

export default Leaderboard;
