import { SelectHTMLAttributes } from "react";

type Props = {
  id: string;
  label?: string;
  items: { title: string; value: string }[];
} & SelectHTMLAttributes<HTMLSelectElement>;

export const InputPicker = (props: Props) => {
  const { label, items, ...rest } = props;

  const symbol = rest.required ? "*" : "";

  return (
    <div className={props.className}>
      {label && (
        <label
          htmlFor={rest.id}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
          <label className="ml-1 font-medium text-red-600">{symbol}</label>
        </label>
      )}
      <select
        {...rest}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 custom-select"
      >
        {items.map((item, index) => (
          <option key={item.value} value={item.value}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};
