import { Popover } from "flowbite-react";
import React, { ButtonHTMLAttributes, useState } from "react";
import { useNavigate } from "react-router-dom";

type LinkType = { title: string; isLink: true; to: string };

type ActionType = { title: string; isLink: false; action: () => void };

export type DropDownViewItem = LinkType | ActionType;

type Props = { items: DropDownViewItem[] };

export function PopoverListGroup(props: React.PropsWithChildren<Props>) {
  const { children, items } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onClickItem = (index: number) => {
    const item = items[index];
    item.isLink ? navigate(item.to) : item.action();
    setOpen(false);
  };

  const content = () => (
    <div className="text-sm font-medium text-gray-900 bg-white rounded-lg dark:bg-gray-700 dark:text-white">
      {items.map((item, index) => (
        <ListItem
          key={index}
          title={item.title}
          onClick={() => onClickItem(index)}
        />
      ))}
    </div>
  );

  return (
    <Popover
      open={open && items.length > 0}
      onOpenChange={setOpen}
      content={content()}
      trigger="click"
    >
      <button>{children}</button>
    </Popover>
  );
}

const ListItem = (
  props: { title: string } & ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <button
      className="min-w-20 flex w-full py-3 pl-3 pr-6 border-b dark:border-slate-600 last:border-b-0 hover:bg-gray-100 hover:dark:bg-gray-800"
      {...props}
    >
      {props.title}
    </button>
  );
};
