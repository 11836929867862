import { Link, useNavigate, useParams } from "react-router-dom";
import { Event } from "../../model/Event";
import moment from "moment";
import { useEffect, useState } from "react";
import EventService from "../../network/service/Event";
import { store } from "../../store/Store";
import { Loader } from "../../components/elements/Loader";
import { UserListItem } from "../../components/elements/UserListItem";
import { NotAvailable } from "../../components/elements/NotAvailable";
import { Section } from "../../components/elements/Section";
import { SectionTitle } from "../../components/elements/SectionTitle";
import { useAppSelector } from "../../store/Hooks";
import Linkify from "linkify-react";

const btnRed =
  "items-center py-2 px-10 text-lg font-medium text-center text-white rounded-lg bg-red-600 hover:bg-red-700";
const btnBlue =
  "items-center py-2 px-10 text-lg font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800";

export const EventDetailsPage = () => {
  const { id } = useParams();
  const user = useAppSelector((state) => state.app.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<Event>();

  const fetchData = async () => {
    try {
      setLoading(true);
      const e = await EventService.fetchEventDetails(id!);
      setEvent(e);
    } catch (error) {
      navigate("../");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!event) return <></>;

  const onJoinHandler = async () => {
    try {
      const eventId = id ?? "";
      const userId = store.getState().app.user?.id ?? "";
      setLoading(true);
      await EventService.joinEvent(eventId, userId);
      await fetchData();
    } catch (error) {}
    setLoading(false);
  };

  const onDeleteParticipant = async (userId: string) => {
    try {
      const eventId = id ?? "";
      const userId = store.getState().app.user?.id ?? "";
      setLoading(true);
      await EventService.joinEvent(eventId, userId);
      await fetchData();
    } catch (error) {}
    setLoading(false);
  };

  const onDeleteHandler = async () => {
    try {
      setLoading(true);
      await EventService.deleteEvent(id ?? "");
      navigate("../");
    } catch (error) {}
    setLoading(false);
  };

  const onEditHandler = () => {
    navigate("/add-event", { state: event });
  };

  const hasPassed = false;
  //moment().isAfter(new Date(event.date))

  const renderLink = ({ attributes, content }: any) => {
    const { href, ...props } = attributes;
    return (
      <Link className="text-blue-500 font-medium" to={href} {...props}>
        {content}
      </Link>
    );
  };

  return (
    <div className="flex flex-grow flex-col">
      <Section className="flex flex-wrap flex-grow justify-around   ">
        <div className="max-w-xl my-3">
          <img
            className="rounded"
            src={process.env.REACT_APP_BASE_URL + "api/" + event.image}
            alt=""
          />
        </div>
        <div className="max-w-xl my-3 flex flex-grow flex-col items-start">
          <h1 className="mb-5 text-4xl font-semibold text-gray-900 dark:text-slate-200">
            {event.title}
          </h1>
          {event.points && (
            <h1 className="mb-5 text-2xl font-medium text-gray-900 dark:text-slate-200">
              {"Points: " + event.points}
            </h1>
          )}
          <Linkify options={{ render: renderLink }}>
            <p className="mb-5 text-base sm:text-lg text-gray-900 dark:text-slate-200">
              {event.description}
            </p>
          </Linkify>

          <div className="my-3 flex flex-row w-full items-center justify-between flex-wrap gap-8">
            <h5 className="text-lg text-gray-900 dark:text-slate-200 mr-6">
              {moment(event.date).format("MMM DD, yyyy [at] hh:mm A")}
            </h5>
            {user && user.role == "admin" && (
              <div className="mt-1">
                <button onClick={() => onEditHandler()}>
                  <i className="mr-4 bi bi-pencil-square cursor-pointer text-2xl text-dark dark:text-white"></i>
                </button>
                <button onClick={onDeleteHandler}>
                  <i className="bi bi-trash cursor-pointer text-2xl text-red-500"></i>
                </button>
              </div>
            )}
          </div>
          {!event.isJoined && (
            <button
              disabled={hasPassed}
              className={`mt-2 ${event.isJoined ? btnRed : btnBlue} ${
                hasPassed ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={onJoinHandler}
            >
              {event.isJoined ? "Leave" : "Join"}
            </button>
          )}
        </div>
      </Section>
      <Section>
        <SectionTitle title="Participants" />
        <ul className="px-8">
          {event.participants.map((p, i) => {
            return (
              <UserListItem
                className="bg-white dark:bg-dark"
                key={p.id}
                user={p}
                onDelete={onDeleteParticipant.bind(this, p.id)}
              />
            );
          })}
        </ul>
        {event && event.participants.length == 0 && (
          <NotAvailable message="No participant joined yet" />
        )}
      </Section>
      <Loader loading={loading} />
    </div>
  );
};
