import { EventCard } from "./EventCard";
import { Loader } from "./Loader";
import { Event } from "../../model/Event";
import { NotAvailable } from "./NotAvailable";
import { useEffect, useState } from "react";
import EventService from "../../network/service/Event";
import { useNavigate } from "react-router-dom";

type Props = { type: "upcoming" | "passed" };

export const EventList = (props: Props) => {
  const { type } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<Event[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const t = await EventService.fetchEvents(type);
        setEvents(t);
      } catch (error) {
        navigate("../");
      }
      setLoading(false);
    };
    fetchData();
  }, [type]);

  return (
    <div className="min-h-48 flex flex-col">
      {events && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 justify-items-center">
          {events.map((event) => (
            <EventCard key={event.id} event={event} />
          ))}
        </div>
      )}
      {events && events.length == 0 && (
        <NotAvailable message="Events not available" />
      )}
      <Loader loading={loading} mode="view" />
    </div>
  );
};
